import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'
import styles from '../styles/home.module.scss'
import logo from '../images/logo.svg'

export default ({data}) => {
  return (
    <Layout>
      <Head title="Home" />
      <div className={styles.home}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          <h1>
            Sharksmouth <span className={styles.light}>Press</span>
          </h1>
          <div
            className={styles.paragraph}
            dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.edges[0].node.html }}
          />

        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(pages/index.md)/" } }
    ) {
      edges {
        node {
          html
        }
      }
    }
  }
`
